export function useSalaryTypeFormat(salaryType: string) {
  const salaryTypeLower = salaryType.toLowerCase()
  if (salaryTypeLower.includes('month'))
    return 'month'
  else if (salaryTypeLower.includes('year'))
    return 'year'
  else if (salaryTypeLower.includes('day'))
    return 'day'
  else if (salaryTypeLower.includes('hour'))
    return 'hour'
  else
    return salaryType.slice(0, -2)
}
